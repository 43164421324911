<template>
  <PassTotalsTable />
</template>

<script>
import PassTotalsTable from "@/v3components/Datatables/PassTotalsTable.vue"
export default {
  name: "PassTotals",
  components: { PassTotalsTable }
}
</script>
