<template>
  <div>
    <div class="profile-avatar">
      <Loader :is-processing="state.isLoading" classes="position-absolute" />
      <CImage
        id="profile-avatar"
        :src="user.avatar ? user.avatar : state.defaultAvatar"
        class="mb-2 user-avatar-image"
      />
      <div v-if="showControls && userRole !== 'student'" class="upload-btn">
        <input
          type="file"
          :accept="acceptedProfileImageType.join(',')"
          @change="getFile"
        />
        <i class="ri-camera-line"></i>
      </div>
      <div
        v-if="showControls && userRole !== 'student' && user.avatar !== null"
        class="d-flex justify-content-center"
      >
        <span class="delete-image" @click="uploadAvatar(null)"
          ><i class="ri-delete-bin-6-line"></i> Delete image</span
        >
      </div>
    </div>
    <div v-if="state.errorMessage" class="invalid-feedback d-block">
      {{ state.errorMessage }}
    </div>
  </div>
</template>

<script>
import { reactive } from "vue"
import { useStore } from "vuex"
import Loader from "@/v3components/shared/Loader/Loader"

export default {
  name: "Avatar",
  components: { Loader },
  emits: ["changeAvatar"],
  props: {
    user: {
      type: Object
    },
    userRole: {
      type: String,
      default: ""
    },
    showControls: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      defaultAvatar: "/img/avatars/user-avatar.jpeg",
      isLoading: false,
      errorMessage: ""
    })

    const acceptedProfileImageType = ["image/png", "image/jpeg", "image/jpg"]

    const getFile = (event) => {
      let avatar = null
      state.errorMessage = ""
      const files = event.target.files
      avatar = files && files.length ? files[0] : avatar
      if (avatar) {
        if (avatar.size > 2048000) {
          state.errorMessage =
            "This image is too large. Allowed maximum size is 2MB"
        } else if (!acceptedProfileImageType.includes(avatar.type)) {
          state.errorMessage = "Unsupported image type"
        } else {
          uploadAvatar(avatar)
        }
      }
      event.target.value = null
    }

    const uploadAvatar = (avatar) => {
      state.isLoading = true
      const data = {
        initials: props.user.user_initials,
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        allow_appointment_requests: props.user.allow_appointment_requests,
        allow_passes_to_me: props.user.allow_passes_to_me,
        is_searchable: props.user.is_searchable
      }

      if (avatar) {
        const formData = new FormData()
        formData.append("avatar", avatar)
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element)
          }
        }

        updateProfile(formData, avatar)
      } else {
        store
          .dispatch("authentication/deleteUserAvatar")
          .then(() => {
            deleteImage()
            state.isLoading = false
            store.dispatch("authentication/getUserDetails", {
              clearCacheEntry: true
            })
            store.dispatch("authentication/getUserSettings", {
              clearCacheEntry: true
            })
          })
          .catch(() => {
            store.isLoading = false
          })
      }
    }

    const updateProfile = (data, avatar) => {
      store
        .dispatch("authentication/updateUserProfile", data)
        .then(() => {
          showUploadedImage(avatar)
          state.isLoading = false
          store.dispatch("authentication/getUserDetails", {
            clearCacheEntry: true
          })
          store.dispatch("authentication/getUserSettings", {
            clearCacheEntry: true
          })
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          state.errorMessage = response.message
        })
    }

    const showUploadedImage = (file) => {
      emit("changeAvatar", file)
      const avatar = document.getElementById("profile-avatar")
      avatar.src = URL.createObjectURL(file)
    }

    const deleteImage = () => {
      emit("changeAvatar", "delete")
      const avatar = document.getElementById("profile-avatar")
      avatar.src = state.defaultAvatar
    }

    return {
      state,
      getFile,
      uploadAvatar,
      acceptedProfileImageType
    }
  }
}
</script>
