<template>
  <div>
    <div class="detention-calendar-container">
      <div class="detention-calendar-container-header-buttons">
        <PillButton
          @click="today"
          class="today-button"
          :class="!todayDisabled ? 'disabled' : ''"
          >Today</PillButton
        >
        <IconButton
          icon="ri-arrow-left-s-line"
          class="me-3"
          :class="!state.prevHide ? 'disabled' : ''"
          @click="clickPrev"
          >Prev</IconButton
        >
        <IconButton
          icon="ri-arrow-right-s-line"
          class="me-3"
          :class="!state.nextHide ? 'disabled' : ''"
          @click="clickNext"
          >Next</IconButton
        >
      </div>

      <div class="detention-calendar-container-fullcalendar">
        <FullCalendar
          id="detention-calendar"
          ref="calendar"
          :options="calendarOptions"
        >
        </FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, computed, ref, inject } from "vue"
import { useStore } from "vuex"
import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import DailyDetentionsForm from "@/v3components/Forms/DailyDetentionsForm"
import IconButton from "@/v3components/shared/Buttons/IconButton"
import PillButton from "@/v3components/shared/Buttons/PillButton"
import helpers from "@/helpers/index"
import moment from "moment-timezone"

export default {
  name: "TardyDetentionCalendar",
  components: { FullCalendar, IconButton, PillButton },

  setup() {
    const calendar = ref(null)
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      prevHide: true,
      nextHide: true
    })

    const detentionsCalendarData = computed(
      () => store.getters["detentions/detentionsCalendarData"]
    )

    const minSchoolDate = computed(() => {
      return helpers.minSchoolYearDate()
    })

    const maxSchoolDate = computed(() => {
      return helpers.maxSchoolYearDate()
    })

    const todayDisabled = computed(() => {
      return (
        moment().format("YYYY-MM-DD") >=
          moment(minSchoolDate.value).format("YYYY-MM-DD") &&
        moment().format("YYYY-MM-DD") <=
          moment(maxSchoolDate.value).format("YYYY-MM-DD")
      )
    })

    const calendarOptions = reactive({
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        start: "title",
        center: "",
        end: ""
      },
      firstDay: 0,
      events: detentionsCalendarData,

      dateClick: (arg) => handleDateClick(arg)
    })

    onMounted(() => {
      changeMonth()
      getDetentions()
      handleCalendarResize()
    })

    const getDetentions = () => {
      store.dispatch("detentions/getDetentionsCached", {
        clearCacheEntry: true
      })
    }

    const getDetentionCalendarData = (startDate, endDate) => {
      const params = {
        startDate: startDate,
        endDate: endDate
      }
      store.dispatch("detentions/getDetentionsCalendarData", params)
    }

    const handleDateClick = (day) => {
      const daySelected = moment(day.dateStr).utc()
      showDailyDetentionsModal(daySelected)
    }

    const today = () => {
      calendar.value.calendar.today()
      setTimeout(() => {
        const todayMonth = moment(
          calendar.value.calendar.currentData.viewTitle
        ).add(1, "M")._d
        const todayMonthFormat = moment(todayMonth).format("YYYY-MM")
        const startSchoolYear = moment(minSchoolDate.value).format("YYYY-MM")
        const endSchoolYear = moment(maxSchoolDate.value).format("YYYY-MM")

        if (todayMonthFormat == startSchoolYear) {
          state.prevHide = false
          state.nextHide = true
        } else if (todayMonthFormat == endSchoolYear) {
          state.prevHide = true
          state.nextHide = false
        } else {
          state.prevHide = true
          state.nextHide = true
        }
        changeMonth()
      }, 100)
    }

    const clickPrev = () => {
      calendar.value.calendar.prev()
      setTimeout(() => {
        const selectedMonth = moment(
          calendar.value.calendar.currentData.viewTitle
        ).format("YYYY-MM")
        const startSchoolYear = moment(minSchoolDate.value).format("YYYY-MM")

        if (selectedMonth <= startSchoolYear) {
          changeMonth()
          state.prevHide = false
        } else {
          changeMonth()
          state.prevHide = true
          state.nextHide = true
        }
      }, 100)
    }

    const clickNext = () => {
      calendar.value.calendar.next()
      setTimeout(() => {
        const selectedMonth = moment(
          calendar.value.calendar.currentData.viewTitle
        ).add(1, "M")._d
        const selectedMonthFormat = moment(selectedMonth).format("YYYY-MM")
        const endSchoolYear = moment(maxSchoolDate.value).format("YYYY-MM")

        if (selectedMonthFormat >= endSchoolYear) {
          changeMonth()
          state.nextHide = false
        } else {
          changeMonth()
          state.prevHide = true
          state.nextHide = true
        }
      }, 100)
    }

    const changeMonth = () => {
      const start = moment(
        calendar.value.calendar.currentData.dateProfile.activeRange.start
      ).format("YYYY-MM-DD")

      const end = moment(
        calendar.value.calendar.currentData.dateProfile.activeRange.end
      ).format("YYYY-MM-DD")
      getDetentionCalendarData(start, end)
    }

    const showDailyDetentionsModal = (day) => {
      const selectedDay = moment(day._d).format("MM/DD/YYYY")
      const endSchoolYear = helpers.currTzDate(maxSchoolDate.value)
      if (
        moment(selectedDay).isSameOrAfter(minSchoolDate.value) &&
        moment(selectedDay).isSameOrBefore(endSchoolYear)
      ) {
        modal.open(DailyDetentionsForm, {
          size: "sm",
          visible: true,
          hideHeader: true,
          title: "Daily detentions",
          props: { selectedDate: day }
        })
      }
    }

    const handleCalendarResize = () => {
      let timeOut = null
      const detentionCalendar = document.getElementById("detention-calendar")

      const resizeObserver = new ResizeObserver(() => {
        clearTimeout(timeOut)
        timeOut = setTimeout(() => {
          if (calendar.value && calendar.value.calendar) {
            calendar.value.calendar.updateSize()
          }
        }, 100)
      })

      resizeObserver.observe(detentionCalendar)
    }
    return {
      state,
      todayDisabled,
      calendarOptions,
      detentionsCalendarData,
      handleDateClick,
      today,
      calendar,
      clickPrev,
      clickNext,
      changeMonth
    }
  }
}
</script>
<style lang="scss" scoped>
.calendar-help-button {
  margin: 3px 0 0 150px !important;
}
</style>
